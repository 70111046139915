import React from 'react';
import Main from '../containers/Layout';
import pricingToolkitPageContent from '../../content/pages/pricing.yml';
import PricingForToolkitContainer from '@/containers/toolkit/PricingForToolkitContainer';

export interface PricingToolkitPageContent {
  title: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    pricingCardUrl: string;
    saasLabel: string;
    paymentsLabel: string;
    // recurringPaymentsLabel: string;
  };

  saasPricingCards: {
    cardTitle1: string;
    cardTitle2: string;
    productsHubLabel: string;
    productHubItems: {
      item: string;
    }[];
    b2bHubLabel: string;
    b2bHubItems: { item: string }[];
    // serviceHubLabel1: string;
    // serviceHubLabel2: string;
    // serviceHubItems: {
    //   item: string;
    // }[];
  }[];

  paymentsOnlyTable: {
    text1: string;
    text2: string;
    worth: string;
    price: string;
  }[];

  // recurringPaymentsCards: {
  //   cardTitle1: string;
  //   cardTitle2: string;
  //   items: {
  //     label?: string;
  //     item: string;
  //   }[];
  // }[];

  lowerText: {
    lowerTitle1: string;
    lowerTitle2: string;
    lowerText: string;
    button: {
      label: string;
      url: string;
    };
  };
}
const Pricing: React.FunctionComponent = () => {
  const {
    title,
    saasPricingCards,
    paymentsOnlyTable,
    // recurringPaymentsCards,
    lowerText,
  } = pricingToolkitPageContent as unknown as PricingToolkitPageContent;
  return (
    <Main>
      <PricingForToolkitContainer
        content={{
          title,
          saasPricingCards,
          paymentsOnlyTable,
          // recurringPaymentsCards,
          lowerText,
        }}
      />
    </Main>
  );
};

export default Pricing;
