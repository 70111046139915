import React from 'react';
import styled from 'styled-components';
import { H1, P, Subtitle2 } from '@/components/Typography';
import Section from '@/components/Section';
import BottomMessage from '@/components/BottomMessage';
import PricingToolkitCard from '@/components/toolkit/PricingToolkitCard';
import PricingToolkitPaymentOnlyTable from '@/components/toolkit/PricingToolkitPaymentCard';
import { PricingToolkitPageContent } from '@/pages/pricing';

const PageWrapper = styled.section`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 247, 249, 1) 100%
  );
`;
const FirstViewport = styled(Section)`
  display: flex;
  justify-content: center;
`;

const Title = styled(Subtitle2)`
  text-align: center;
  margin-bottom: 2rem;
  :nth-of-type(2n) {
    margin-top: 5rem;
  }
`;

// const RpTitle = styled(Subtitle2)`
//   text-align: center;
//   margin-bottom: 2rem;
//   margin-top: 5rem;
// `;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Description = styled(P)`
  margin-top: 2rem;
`;
const PricingCards = styled(Section)`
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ThirdViewport = styled(Section)`
  padding-top: 0;
`;

const TableWrapper = styled(Section)`
  padding-top: 1rem;
`;
interface PricingToolkitPageProps {
  content: PricingToolkitPageContent;
}
const PricingForToolkitContainer: React.FunctionComponent<
  PricingToolkitPageProps
> = ({
  content: {
    title,
    saasPricingCards,
    paymentsOnlyTable,
    // recurringPaymentsCards,
    lowerText,
  },
}) => (
  <PageWrapper>
    <FirstViewport>
      <Text>
        <H1>
          {title.title1} <span className="accent-text">{title.title2}</span>
          {` `}
          {title.title3}
        </H1>
        <Description>{title.description}</Description>
      </Text>
    </FirstViewport>

    <Title>{title.saasLabel}</Title>

    <PricingCards>
      {saasPricingCards.map((item) => (
        <PricingToolkitCard data={item} />
      ))}
    </PricingCards>

    <Title>{title.paymentsLabel}</Title>
    <TableWrapper>
      <PricingToolkitPaymentOnlyTable
        // flexBasis="25%"
        data={paymentsOnlyTable}
      />
    </TableWrapper>

    {/* <RpTitle>{title.recurringPaymentsLabel}</RpTitle> */}
    {/* <PricingCards>
      {recurringPaymentsCards.map((item) => (
        <PricingToolkitPaymentCard flexBasis="25%" data={item} />
      ))}
    </PricingCards> */}

    <ThirdViewport>
      <BottomMessage
        title1={lowerText.lowerTitle1}
        title2={lowerText.lowerTitle2}
        text={lowerText.lowerText}
        button={lowerText.button}
      />
    </ThirdViewport>
  </PageWrapper>
);

export default PricingForToolkitContainer;
