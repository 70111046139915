import React from 'react';
import styled from 'styled-components';
import { Body2, Body4, P, Subtitle2 } from '../Typography';
import GreenTickIcon from '../../assets/icons/tick.svg';
import ButtonDefault from '../ButtonDefault';

const Wrapper = styled.div<{ flexBasis: string }>`
  flex-basis: ${(props) => (props.flexBasis ? `${props.flexBasis}` : `20%`)};
  max-width: ${(props) => (props.flexBasis ? `initial` : `250px`)};
  min-width: ${(props) => (props.flexBasis ? `initial` : `250px`)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem 1rem;
  flex-grow: 1;
  border: 1px solid #dbe3eb;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    box-shadow: 0px 4px 30px rgba(194, 206, 219, 0.6);
    transform: scale(1.05);
  }
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
    &:hover {
      transform: none;
    }
  }
`;

const Tick = styled(GreenTickIcon)`
  color: #2cd19e;
  margin-right: 0.5rem;
  padding-top: 5px;
`;

const Title = styled(Subtitle2)`
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  color: #2cd19e;
`;

const SubTitle = styled(P)`
  font-weight: 500;
`;

const SubDescriptionItems = styled.div`
  display: flex;
`;

const Content = styled.div`
  min-height: 33rem;
`;

// const BottomTitle = styled(Title)`
//   text-align: center;
//   font-size: 23px;
//   line-height: 33px;
//   margin-top: 2rem;
//   padding: 0 2rem;
// `;

const Label = styled(Body2)`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
`;

const Ul = styled.div`
  margin-bottom: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const Footer = styled(Header)`
//   min-height: 9rem;
// `;

const StyledButtonDefault = styled(ButtonDefault)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

interface PricingToolkitCard {
  data: {
    cardTitle1: string;
    cardTitle2: string;
    productsHubLabel: string;
    productHubItems: {
      item: string;
    }[];

    b2bHubLabel: string;
    b2bHubItems: { item: string }[];
    // serviceHubLabel1: string;
    // serviceHubLabel2: string;
    // serviceHubItems: {
    //   item: string;
    // }[];
  };
  flexBasis?: string;
}

const PricingToolkitCard: React.FunctionComponent<PricingToolkitCard> = ({
  data,
  flexBasis,
}) => (
  <Wrapper flexBasis={flexBasis}>
    <Header>
      <Title>{data.cardTitle1}</Title>
      <SubTitle>{data.cardTitle2}</SubTitle>
    </Header>
    <Content>
      <Label>{data.productsHubLabel}</Label>
      {data.productHubItems.map((productHubItem) => (
        <Ul>
          <SubDescriptionItems>
            <div>
              <Tick />
            </div>
            <Body4>{productHubItem.item}</Body4>
          </SubDescriptionItems>
        </Ul>
      ))}
      <Label>{data.b2bHubLabel}</Label>
      {data.b2bHubItems.map((b2bHubItem) => (
        <Ul>
          <SubDescriptionItems>
            <div>
              <Tick />
            </div>
            <Body4>{b2bHubItem.item}</Body4>
          </SubDescriptionItems>
        </Ul>
      ))}
    </Content>
    {/* <Footer>
      <BottomTitle>{data.serviceHubLabel1}</BottomTitle>
      <SubTitle>{data.serviceHubLabel2}</SubTitle>
    </Footer>
    {data.serviceHubItems.map((serviceHubItem) => (
      <Ul>
        <SubDescriptionItems>
          <div>
            <Tick />
          </div>
          <Body4>{serviceHubItem.item}</Body4>
        </SubDescriptionItems>
      </Ul>
    ))} */}
    <StyledButtonDefault to="https://toolkit.fena.co/sign-up/" white>
      Choose plan
    </StyledButtonDefault>
  </Wrapper>
);

export default PricingToolkitCard;
