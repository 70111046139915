import React from 'react';
import styled from 'styled-components';
import ButtonDefault from '../ButtonDefault';

const Wrapper = styled.div<{ flexBasis: string }>`
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 30px;
`;

const Table = styled.table`
  width: 100%;

  border-spacing: 0;
  tbody {
    tr {
      :hover {
        background-color: #f2f4ff;
      }
    }
  }
`;

const Thead = styled.thead`
  background-color: #f4f5f9;
  z-index: 10;
`;

const Tr = styled.tr`
  :last-child {
    td {
      border-bottom: 0;
    }
  }
`;

const Th = styled.th`
  position: relative;
  text-align: left;
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #13273f;
  :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const Td = styled.td`
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
  color: #13273f;
  cursor: pointer;
  :last-child {
    border-right: 0;
  }
`;

const Bold = styled.span`
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const PriceWrapper = styled(Td)`
  max-width: 100px;
`;
interface PricingToolkitCard {
  flexBasis?: string;
  data: {
    text1: string;
    text2: string;
    worth: string;
    price: string;
  }[];
}

const PricingToolkitPaymentOnlyTable: React.FunctionComponent<
  PricingToolkitCard
> = ({ data, flexBasis }) => (
  <Wrapper flexBasis={flexBasis}>
    <Table>
      <Thead>
        <Tr>
          <Th>Worth of transaction</Th>
          <Th>Price</Th>
        </Tr>
      </Thead>

      <tbody>
        {data?.map((el) => (
          <tr key={el.price}>
            <Td>
              {el.text1} <Bold>{el.worth}</Bold> {el.text2}
            </Td>
            <PriceWrapper>
              <Bold>{el.price}</Bold>
            </PriceWrapper>
            {/* <Td></Td> */}
          </tr>
        ))}
      </tbody>
    </Table>
    <ButtonWrapper>
      <ButtonDefault to="https://toolkit.fena.co/sign-up/" white>
        Sign up now
      </ButtonDefault>
    </ButtonWrapper>
  </Wrapper>
);

export default PricingToolkitPaymentOnlyTable;
